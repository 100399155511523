<template>
    <main class="round-post-page">

        <section>
            <div class="container py-5">
                <div class="row justify-content-center">
                    <div class="sm-down:col-12 md:col-4 xl:col-3">
                        <mega-card class="rounded">

                            <router-link class="card-media" :to="{name: 'Round_Post_Photo', params: {arr_id}}">

                                <div class="ratio r-4x3"></div>

                                <div class="media-overlay overlay-100 overlay-white">
                                    <div class="text-center">
                                        <span class="sticker sticker-lg bg-dark"><i class="i-image"></i></span>
                                    </div>
                                </div>
                            </router-link>

                            <router-link class="btn px-4 w-100 bg-white" :to="{name: 'Round_Post_Photo', params: {arr_id}}">{{ $t('round_photo') }}</router-link>
                        </mega-card>
                    </div>
                    <div class="sm-down:col-12 md:col-4 xl:col-3">
                        <mega-card class="rounded">

                            <router-link class="card-media" :to="{name: 'Round_Post_3D', params: {arr_id}}">

                                <div class="ratio r-4x3"></div>

                                <div class="media-overlay overlay-100 overlay-white">
                                    <div class="text-center">
                                        <span class="sticker sticker-lg bg-dark"><i class="i-package"></i></span>
                                    </div>
                                </div>
                            </router-link>

                            <router-link class="btn px-4 w-100 bg-white" :to="{name: 'Round_Post_3D', params: {arr_id}}">{{ $t('round_3d') }}</router-link>
                        </mega-card>
                    </div>
					<div class="sm-down:col-12 md:col-4 xl:col-3">
                        <mega-card class="rounded">

                            <router-link class="card-media" :to="{name: 'Round_Post_Youtube', params: {arr_id}}">

                                <div class="ratio r-4x3"></div>

                                <div class="media-overlay overlay-100 overlay-white">
                                    <div class="text-center">
                                        <span class="sticker sticker-lg bg-dark"><i class="i-youtube-solid"></i></span>
                                    </div>
                                </div>
                            </router-link>

                            <router-link class="btn px-4 w-100 bg-white" :to="{name: 'Round_Post_Youtube', params: {arr_id}}">{{ $t('round_yt') }}</router-link>
                        </mega-card>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    export default {
        name: 'RoundPostPage',
        props: ['arr_id'],
        mounted() {
            this.$navbar.name = this.$t('round_create_title');
        }
    }
</script>
