var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "round-post-page" }, [
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c(
            "div",
            { staticClass: "sm-down:col-12 md:col-4 xl:col-3" },
            [
              _c(
                "mega-card",
                { staticClass: "rounded" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "card-media",
                      attrs: {
                        to: {
                          name: "Round_Post_Photo",
                          params: { arr_id: _vm.arr_id }
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "ratio r-4x3" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "media-overlay overlay-100 overlay-white"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "span",
                              { staticClass: "sticker sticker-lg bg-dark" },
                              [_c("i", { staticClass: "i-image" })]
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn px-4 w-100 bg-white",
                      attrs: {
                        to: {
                          name: "Round_Post_Photo",
                          params: { arr_id: _vm.arr_id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("round_photo")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sm-down:col-12 md:col-4 xl:col-3" },
            [
              _c(
                "mega-card",
                { staticClass: "rounded" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "card-media",
                      attrs: {
                        to: {
                          name: "Round_Post_3D",
                          params: { arr_id: _vm.arr_id }
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "ratio r-4x3" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "media-overlay overlay-100 overlay-white"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "span",
                              { staticClass: "sticker sticker-lg bg-dark" },
                              [_c("i", { staticClass: "i-package" })]
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn px-4 w-100 bg-white",
                      attrs: {
                        to: {
                          name: "Round_Post_3D",
                          params: { arr_id: _vm.arr_id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("round_3d")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "sm-down:col-12 md:col-4 xl:col-3" },
            [
              _c(
                "mega-card",
                { staticClass: "rounded" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "card-media",
                      attrs: {
                        to: {
                          name: "Round_Post_Youtube",
                          params: { arr_id: _vm.arr_id }
                        }
                      }
                    },
                    [
                      _c("div", { staticClass: "ratio r-4x3" }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "media-overlay overlay-100 overlay-white"
                        },
                        [
                          _c("div", { staticClass: "text-center" }, [
                            _c(
                              "span",
                              { staticClass: "sticker sticker-lg bg-dark" },
                              [_c("i", { staticClass: "i-youtube-solid" })]
                            )
                          ])
                        ]
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "router-link",
                    {
                      staticClass: "btn px-4 w-100 bg-white",
                      attrs: {
                        to: {
                          name: "Round_Post_Youtube",
                          params: { arr_id: _vm.arr_id }
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("round_yt")))]
                  )
                ],
                1
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }